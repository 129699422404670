import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import { graphql } from 'gatsby';
import Grid from '../components/soul/layout/grid/Grid';
import { GridDensity } from '../components/soul/layout/grid/GridDensity';
import GridItem from '../components/soul/layout/grid/GridItem';
import { GridItemSize } from '../components/soul/layout/grid/GridItemSize';
import { GridAlignment } from '../components/soul/layout/grid/GridAlignment';
import Img, { FluidObject } from 'gatsby-image';
import InternalLink from '../components/soul/navigation/InternalLink';
import Sign from '../components/soul/layout/sign/Sign';
import { IconSize } from '../components/soul/layout/sign/IconSize';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface WhyAttendPageProps {
    data: {
        file: {
            childWhyAttendJson: {
                title: string;
                reasons: AttendReason[]
            }
        }
    }
}

interface AttendReason {
    weight: number;
    title: string;
    content: string;
    link?: {
        href: string,
        label: string,
        icon: string,
        enabled: boolean
    }
    image: {
        childImageSharp: {
            fluid: FluidObject
        }
    }
}

const WhyAttendPage: React.FC<WhyAttendPageProps> = ({data}) => {
    const whyAttend = data.file.childWhyAttendJson;
    const sortedReasons = whyAttend.reasons.sort((a, b) => a.weight - b.weight);
    const key = (index: number) => `reason-${index}`;
    const image = (reason: AttendReason) => reason.image.childImageSharp.fluid;

    return (
        <SingleColumnLayout>
            <SEO
                title='ESTECO UM20 | Five good reasons to attend'
                description='Discover the most innovative applications of VOLTA and modeFRONTIER. Access dedicated training, share your design experience and meet ESTECO experts.'/>
            <div className="centered-column">
                <Grid density={GridDensity.WIDE} alignment={GridAlignment.TOP}>
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'>
                        <h1 className="page-title">{whyAttend.title}</h1>
                    </GridItem>
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole'/>

                    {sortedReasons.map((reason: AttendReason, index: number) => (
                        <React.Fragment key={key(index)}>
                            <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole soul-space-stack-bottom-2-xl'>
                                <Img fluid={image(reason)} alt={reason.title}/>
                            </GridItem>

                            <GridItem size={GridItemSize.ONE_HALF} additionalClasses='l-palm--one-whole soul-space-stack-bottom-2-xl'>
                                <h2 className="soul-font-size-xl h-text-bold h-listed-element soul-space-stack-bottom-m">{reason.title}</h2>
                                <div className="soul-content--m  soul-font-size-l">
                                    {reason.content}
                                </div>
                                {reason.link ? (
                                    <div className='soul-space-stack-top-m'>
                                    <InternalLink to={reason.link.href} enabled={reason.link.enabled}
                                                  className='c-link  soul-font-size-m' >
                                        <Sign iconSize={IconSize.ICON_L} icon={reason.link.icon} label={reason.link.label} reverse/>
                                    </InternalLink>
                                    </div>
                                ) : ''}
                            </GridItem>
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
    query RetrievePageContent {
        file(relativeDirectory: {eq: "whyAttend"}, name: {eq: "why-attend-content"}) {
            childWhyAttendJson {
                title
                reasons {
                    weight
                    title
                    content
                    link {
                        href
                        label
                        icon
                        enabled
                    }
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default WhyAttendPage;
